import { useLoaderData } from "react-router-dom";
import WelcomeTitle from "../components/WelcomeTitle";

export default function CrowdfundingManagePage() {
    // @ts-ignore
    const { contract } = useLoaderData();

    console.log(contract);

    return (
        <div className="container">
            <main className="main">
                <h1 className="title">
                    <WelcomeTitle linkText={"Manage Page"} />
                </h1>

                <p className="description">
                    Get started by configuring your desired network in{" "}
                    <code className="code">src/index.tsx</code>, then modify the{" "}
                    <code className="code">src/App.tsx</code> file!
                </p>

                { contract ? <p>{contract}</p> : <></>}
            </main>
    </div>
    );
}