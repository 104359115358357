import App from "./App";
import "./styles/globals.css";
import { createBrowserRouter } from "react-router-dom";
import RootPage from "./pages/RootPage";
import CrowdfundingRootPage from "./pages/CrowdfundingRootPage";
import CrowdfundingIndexPage from "./pages/CrowdfundingIndexPage";
//import CrowdfundingCreatePage from "./pages/CrowdfundingCreatePage";
import CrowdfundingManagePage from "./pages/CrowdfundingManagePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <RootPage />
      },
      {
        path: "crowdfunding",
        element: <CrowdfundingRootPage />,
        children: [
          {
            index: true,
            element: <CrowdfundingIndexPage />
          },
          /*{
            path: "create",
            element: <CrowdfundingCreatePage />
          },*/
          {
            path: "manage/:contract",
            loader: ({ params }) => {
              return params;
            },
            element: <CrowdfundingManagePage />
          },
        ]
      },
      {
        path: "pricing",
        element: <div>Hello pricing!</div>
      },
      {
        path: "blog",
        element: <div>Hello blog!</div>
      }
    ]
  }
]);

export default router;
