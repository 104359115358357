import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import WelcomeTitle from "../components/WelcomeTitle";
import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { enqueueSnackbar } from "notistack";

//@ts-ignore
const ColoredCircle = ({ color }) => {

    const styles = { backgroundColor: color };

    return color ? (
        <Fragment>
            <span className="colored-circle" style={styles} />
        </Fragment>
    ) : null;
};

//@ts-ignore
const StatusCircles = ({ running, starting }) => {
    if (running) {
        return <ColoredCircle color="#00ff00"></ColoredCircle>;
    } else if (!running && starting) {
        return <ColoredCircle color="#ffa500"></ColoredCircle>;
    } else {
        return <ColoredCircle color="#ff0000"></ColoredCircle>;
    }
}

export default function RootPage() {
    const theme = useTheme();
    const { t } = useTranslation();
    const [serverStarting, setServerStarting] = useState(false);
    const [serverRunning, setServerRunning] = useState(false);

    const serverStart = (event: any) => {
        fetch('https://jb954tpbd0.execute-api.eu-central-1.amazonaws.com/prod/start', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function (response) {
                //console.log(response)
                return response.json();
            })
            .then(function (myJson) {
                console.log(myJson);

                if (myJson.hasOwnProperty("statusCode") && myJson.statusCode === 200) {
                    enqueueSnackbar('Server starting...', {
                        variant: 'success'
                    });
                    //setServerRunning(true);
                    setServerStarting(true);
                }
                //setData(myJson)
            });
    }

    useEffect(() => {
        console.log("Checking server status...");
        /*const req = new XMLHttpRequest();
        req.addEventListener("load", requestListener);
        req.open("GET", "https://jb954tpbd0.execute-api.eu-central-1.amazonaws.com/prod/status");
        //req.setRequestHeader("Access-Control-Request-Method", "GET");
        //req.setRequestHeader("Access-Control-Request-Headers", "Content-Type");
        //req.setRequestHeader("X-Custom-Header", "test");
        req.send();*/

        fetch('https://jb954tpbd0.execute-api.eu-central-1.amazonaws.com/prod/status', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function (response) {
                //console.log(response)
                return response.json();
            })
            .then(function (myJson) {
                //console.log(myJson);

                if (myJson.hasOwnProperty("statusCode") && myJson.statusCode === 200) {
                    //var jsonObject = JSON.parse(myJson);

                    if (myJson.hasOwnProperty("body")) {
                        var body: string = myJson.body;

                        var bodyObj = JSON.parse(body);

                        //console.log(body);
                        console.log(bodyObj);

                        if (bodyObj.hasOwnProperty("InstanceStatuses") && bodyObj.InstanceStatuses.length > 0) {
                            var instanceStatus = bodyObj.InstanceStatuses[0];

                            console.log(instanceStatus);

                            if (instanceStatus.hasOwnProperty("InstanceState")
                                && instanceStatus.InstanceState.hasOwnProperty("Name")
                                && instanceStatus.InstanceState.Name === "running") {
                                    setServerRunning(true);
                            }
                        }
                    }

                    //console.log(jsonObject);
                }
                //setData(myJson)
            });
    }, []);

    useEffect(() => {
        console.log("Server starting updated");

        if (serverStarting) {
            setTimeout(() => {
                console.log("Server running after timeout");
                setServerRunning(true);
            }, 15000);
        }
        
    }, [serverStarting, setServerStarting]);

    useEffect(() => {
        console.log("Server running updated");
        setServerStarting(false);
    }, [serverRunning, setServerRunning]);

    return (
        <div className="container">
            <main className="main">
                <div className="grid">
                    <div className="card">
                        <h2>Status</h2>

                        <StatusCircles starting={serverStarting} running={serverRunning}></StatusCircles>

                        <p>
                            {serverStarting ? 'Server starting' : serverRunning ? 'Server online' : 'Server offline'}
                        </p>
                    </div>

                </div>

                <div className="grid">
                    <Button size="large" disabled={serverStarting || serverRunning} onClick={serverStart}>Server starten</Button>
                </div>
            </main>
        </div>
    );
}